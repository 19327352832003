import {
  Route,
  BrowserRouter as Router,
  Switch
} from "react-router-dom";

import React from "react";

function App() {
  return (
<Router>
      <Switch>
      <Route path="/kwara" render={() => window.location = "https://docs.google.com/forms/d/e/1FAIpQLSfEUgRZ1hOxnfyygDFzKTFODHtnG7xZ1yG0udwv2-VGc-w1UQ/viewform?usp=sf_link"} />
      <Route path="/togo" render={() => window.location = "https://docs.google.com/forms/d/e/1FAIpQLSfnK8I9qkz1NfugmHeOYzoOcBH9iKEdL6NYHSeHRdSnfDuOCw/viewform?usp=sf_link"} />
        <Route path="/bauchi" render={() => window.location = "https://docs.google.com/forms/d/e/1FAIpQLSceJx7rJG4U4XAdM_3BYWe-rzEJnz1NPz_4957v00vXzggnMw/viewform?usp=sf_link"} />
        <Route path="/refer" render={() => window.location = "https://docs.google.com/forms/d/e/1FAIpQLScmdZBqmDqKH64AA3ATozw5FmIvU5Q8M0RNcY7a8OBQkqw7hg/viewform"} />
        <Route path="/ghana" render={() => window.location = "https://docs.google.com/forms/d/e/1FAIpQLSefLe0euaBOYfRBn4kiAMvjiQanOnwxIGQnyNWDWyXRwUiwZg/viewform?usp=sf_link"} />
        <Route path="/mali-burkina-faso" render={() => window.location = "https://docs.google.com/forms/d/e/1FAIpQLSd9Xhmtia9CXCfILpUAdc7fYt4g4V4D7gZZ1oFvLgEQ155H1Q/viewform?usp=sf_link"} />
        <Route path="/50-million-african-women-speak" render={() => window.location = "https://docs.google.com/forms/d/e/1FAIpQLSfEUgRZ1hOxnfyygDFzKTFODHtnG7xZ1yG0udwv2-VGc-w1UQ/viewform?usp=sf_link"} />
        <Route path="/africa" render={() => window.location = "https://docs.google.com/forms/d/e/1FAIpQLSch47Q6wcCJXl3t8MonjtjsYRGf3ol_T6xV2JGYBuUyCUqgTw/viewform"} />
        <Route path="/" render={() => window.location = "https://docs.google.com/forms/d/e/1FAIpQLSdvrLl7E-sUcouAY71p-RHv0u4dLoaDPRVJDR-R4A2PoqEXsQ/viewform?usp=sf_link"}/>
    </Switch>
</Router>
  );
}

export default App;